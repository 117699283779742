@import "./variables";

@mixin xs() {
  @media (min-width: 0px) {
    @content;
  }
}
@mixin sm() {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    @content;
  }
}
@mixin md() {
  @media (min-width: map-get($grid-breakpoints, md)) {
    @content;
  }
}
@mixin lg() {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
}
@mixin xl() {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    @content;
  }
}
@mixin xxl() {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    @content;
  }
}

#mainMenu {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-height: calc(100vh - 69px);
    overflow-y: scroll;
    width: 100%;
  }
}

.sideMenu {
  @include lg() {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 69px;
    left: 0;
    padding-top: 10px;
    background: white;
    box-shadow: $ist-shadow;
    height: calc(100vh - 60px);
    overflow-x: auto;
    z-index: 10000;
  }
  display: none;
  padding: 0 8px;
  .icon-box {
    @media (min-height: 768px) {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      .ist-logo {
        width: 42.8px;
        height: 24.2px;
      }
    }
    display: none;
  }
  .side-list-group {
    border-radius: 0;
    width: 100%;
    .side-menu-item {
      border: 0;
      margin: 0;
      font-size: 14px;
      height: 35px;
      border-radius: 0;
      padding: 0 15px;
      background-color: transparent;
      border-bottom: 1px solid map-get($gray-colors, x-light);
      align-items: center;
      color: #262626e6;
      display: flex;
      &.header {
        font-weight: 700;
        border-bottom: 1px solid map-get($blue-colors, light);
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        height: 20px;
        align-items: flex-end;
        color: map-get($blue-colors, dark);
      }
      &.spacer {
        border-bottom: none;
        height: 6px;
      }
      &:last-child {
        border-bottom: 0;
      }
      &.active {
        background-color: #d9f1f6;
        color: #262626e6;
        font-weight: bold;
        text-decoration: none;
      }
      &.link:hover:not(.active) {
        text-decoration: none;
        background-color: map-get($gray-colors, x-light);
      }
    }
  }
}

.dropdown-item {
  a {
    flex: 1;
  }
  &.active a {
    color: white;
  }
}
#root {
  flex: auto;
  display: flex;
}
.form-group .DayPickerInput {
  width: 100%;
}
