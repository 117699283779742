@import "./variables";

#guardian {
  .card {
    box-shadow: $ist-shadow;
    border-width: 0px;
    border-radius: $card-border-radius;
  }
  .card-header {
    border-radius: $border-radius $border-radius 0 0;
    background-color: map-get($blue-colors, xx-light);
    font-weight: bold;
    border-width: 0px;
    > i {
      float: right;
      color: map-get($green-colors, base);
      font-size: 140%;
    }
  }
  .card.done {
    .card-header {
      background-color: map-get($gray-colors, base);
    }
  }
}
