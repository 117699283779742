@import "./variables";

.rdw-option-wrapper {
  border-color: map-get($gray-colors, light);

  &:hover {
    border-color: map-get($theme-colors, primary);
  }

  &.rdw-option-active {
    border-color: map-get($theme-colors, primary);
    background-color: map-get($blue-colors, xx-light);
    box-shadow: none;
  }
}

.rdw-editor-toolbar {
  border-color: map-get($gray-colors, xx-light);
  background-color: map-get($gray-colors, xx-light);
}

.draftjs-wrapper {
  // padding: 5px;
}

.draftjs-editor {
  border-radius: $border-radius;
  border: 1px solid map-get($blue-colors, base);
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  &.disabled {
    border-color: $custom-control-border-color-disabled;
    background-color: $form-control-text-disabled-color;
  }
}
