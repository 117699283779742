@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;600;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.3.1/css/all.css");

@import "@ist-group/bootstrap-theme-home/src/index.scss";
@import "../../node_modules/react-day-picker/lib/style.css";

@import "./guardian.scss";
@import "./components.scss";

@import "./form_viewer_editor.scss";
@import "./rteditor.scss";
@import "./color";

$grid-breakpoints: map-merge(
  (
    xxl: 1750px,
  ),
  $grid-breakpoints
);

$container-max-widths: map-merge(
  (
    xxl: 1610px,
  ),
  $container-max-widths
);

// Overwrite RFS until properly used.
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

body {
  margin: 0;
  font-size: 14px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.app {
  min-height: 100%;
}

.sk-spinner {
  color: map-get($blue-colors, base) !important;
}

#modal-root .ist-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  padding: 30px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.mainLayout {
  @include media-breakpoint-down(md) {
    padding: 90px 1.5rem;
  }
  @include media-breakpoint-down(xs) {
    padding: 90px 0.75rem;
  }
  padding: 90px 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
}

.mainContentNoSideBar {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.mainContent {
  width: 100%;
  @include lg {
    width: calc(98% - 240px);
    margin-left: 240px;
    max-width: 1200px;
  }
  @include xxl {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
.welcome-description {
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.sk-spinner > div {
  background-color: map-get($theme-colors, primary);
}

#root {
  flex: auto;
  display: flex;
}

footer {
  display: flex;
  width: 100%;
  min-height: 50px;
  background-color: map-get($gray-colors, light);
  @include lg {
    justify-content: start;
  }
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  .footercontent {
    @include lg {
      width: calc(100% - 240px);
      max-width: 1200px;
      margin-left: 240px;
    }
    @include xxl {
      width: calc(100% - 240px);
      margin-left: auto;
      margin-right: auto;
    }
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
      width: auto;
      margin-left: 7px;
      margin-right: 4px;
    }
    div {
      margin-left: 4px;
      margin-right: 7px;
      span {
        padding: 4px;
        font-size: 12px;

        a {
          color: black;
          font-weight: bold;
        }
      }
    }
  }
}

body,
.app {
  margin: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

body {
  overflow-y: auto;
}

.app {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.center-space {
  margin: auto;
  max-width: 90%;
  margin-top: 20px;
}

main {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  &.content {
    @include md {
      width: 95%;
    }
    @include xxl {
      width: 1200px;
    }
  }
  &.top {
    display: flex;
    justify-content: flex-start;
  }
  .start-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: auto;
    border-radius: 3px;
    padding: 20px;
    .login-loader-container {
      display: flex;
      align-items: center;
      height: 3rem;
      margin: 20px;
    }
  }
}

.hover-show {
  visibility: hidden;
  .hover-show-trigger:hover & {
    visibility: visible;
  }
}

.hover-show-text {
  color: map-get($blue-colors, base);
}

div.Select-menu-outer {
  max-height: 400px;
}

div.Select-menu {
  max-height: 400px;
}

.clickable {
  cursor: pointer;
}

.fat {
  font-weight: bold;
}

.not-clickable {
  cursor: default;
}

tbody.clickable-rows > tr:hover,
tr.clickable-row:hover {
  cursor: pointer;
  background-color: map-get($blue-colors, x-light);
}

.icon-button {
  cursor: pointer;
  color: map-get($theme-colors, primary);
}

.ist-container-expandable-small-text {
  font-size: 12px;
  font-weight: 200;
  color: map-get($gray-colors, light);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
}
.button-filter {
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.1;
  padding: 5px 25px 5px 25px;
  border: 1px solid map-get($blue-colors, base);
}

.button-publication {
  height: 30px;
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.1;
  border-radius: 25px;
  padding: 5px 25px 5px 25px;
  border: 1px solid map-get($blue-colors, base);
  &:disabled {
    color: map-get($blue-colors, base);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.1;
    padding: 5px 25px 5px 25px;
    border: 1px solid map-get($blue-colors, base);
    border-radius: 25px;
  }
}

.button-transparent-rounded-big {
  font-size: 12px;
  height: 30px;
  border: 1px solid map-get($blue-colors, base);
  background-color: transparent;
  border-radius: 25px;
  min-width: 185px;
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: inline;
  font-weight: 600;
  opacity: 1;
  &:disabled {
    font-size: 12px;
    height: 30px;
    border: 1px solid map-get($blue-colors, base);
    border-radius: 25px;
    min-width: 185px !important;
    color: map-get($blue-colors, base);
    text-transform: uppercase;
    letter-spacing: 1.2px;
    display: inline;
    font-weight: 600;
  }
}

.button-transparent-rounded-bigger {
  font-size: 12px;
  height: 30px;
  min-width: 220px;
  border: 1px solid map-get($blue-colors, base);
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: inline;
  font-weight: 600;
  border-radius: 25px;
}

.button-transparent-rounded {
  height: 30px;
  width: 120px;
  border: 1px solid map-get($blue-colors, base);
  text-align: center;
  border-radius: 25px;
  opacity: 1;
  background-color: transparent;
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 16px;
  letter-spacing: 1.2px;
}

.button-transparent-rounded-onclick {
  height: 30px;
  width: 120px;
  border: 1px solid map-get($blue-colors, base);
  text-align: center;
  border-radius: 25px;
  opacity: 1;
  background-color: #ffffff;
  color: map-get($blue-colors, base);
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 16px;
  letter-spacing: 1.2px;
}

.button-danger {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: map-get($red-colors, x-dark);
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1;
  padding: 10px 16px 10px 16px;
  opacity: 1;
}

.button-danger-checked {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: map-get($red-colors, xx-dark);
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1;
  padding: 10px 16px 10px 16px;
  opacity: 0.65;
}

.unactive-button {
  min-width: 144px; 
  height: 34px;
  background-color: #FFFFFF;
  color: #000;
  font-size: 13px;
  line-height: 21px;
  align-items: center;
  border: 1px solid #000 !important;
  padding: 0 5px; 
  box-sizing: border-box; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.active-button {
  min-width: 144px; 
  height: 34px;
  background-color: #007E94;
  color: #fff;
  font-size: 13px;
  line-height: 21px;
  align-items: center;
  border: 2px solid #007E94 !important;
  padding: 0 5px; 
  box-sizing: border-box;
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.bg-white {
  background: #ffffff;
}

.form-input {
  border-radius: 8px;
  border-color: map-get($blue-colors, base);
  &::placeholder {
    font-size: 12px;
    padding-left: 5px;
  }
}
button.flat.btn-primary:not(:disabled):not(.disabled):active {
  color: map-get($theme-colors, primary);
}

button.flat:hover,
button.flat:not(:disabled):not(.disabled):active {
  background: transparent;
  color: map-get($theme-colors, base);
}

button.flat {
  padding: 0.1em;
  color: map-get($blue-colors, base);
  background: transparent;
  border: none;
  height: 25px;
  line-height: 1;
}

button.flat.thin {
  padding: 0.1em;
  font-weight: normal;
}

.rounded-search-select__control,
.rounded-search-select__dropdown-indicator,
.rounded-search-select__placeholder,
.rounded-search-select__indicator {
  color: map-get($blue-colors, base);
}

.rounded-search-select__control {
  min-height: 33px !important;
  border-color: map-get($blue-colors, base) !important;
  border-radius: 8px !important;
  position: relative;
  box-shadow: 0px 0px 0px 0px map-get($blue-colors, base);

  &--is-disabled {
    border-color: map-get($blue-colors, base) !important;
    background-color: map-get($blue-colors, xx-light) !important;
    .rounded-search-select__indicator-separator {
      background-color: map-get($blue-colors, base) !important;
    }
    .rounded-search-select__dropdown-indicator,
    .rounded-search-select__indicator {
      color: map-get($blue-colors, dark) !important;
    }
  }
  &--is-focused {
    background-color: #fff;
    border-color: $input-focus-border-color !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 148, 0.25) !important;
  }
}

.rounded-search-select__indicator {
  padding: 6px !important;
}

.rounded-search-select__value-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0%;
}

.rounded-search-select__menu {
  position: absolute !important;
  z-index: 10 !important;
  display: block;
}

.rounded-search-select__placeholder {
  font-size: 12px;
  letter-spacing: 0.02rem;
  padding-top: 2px;
}

.rounded-search-label {
  color: black;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.search-select__control,
.search-select__dropdown-indicator,
.search-select__placeholder,
.search-select__indicator {
  color: map-get($gray-colors, x-dark) !important;
}

.search-select__indicator-separator {
  background-color: map-get($gray-colors, x-dark) !important;
}

.search-select__option--is-focused {
  background-color: map-get($blue-colors, xx-light) !important;
}
.search-select__option--is-selected {
  background-color: map-get($blue-colors, x-light) !important;
  color: black !important;
}

.search-select__control {
  min-height: 38px;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: map-get($blue-colors, base) !important;
  border-radius: $border-radius;
  &--is-disabled {
    border-color: map-get($gray-colors, base) !important;
    background-color: map-get($gray-colors, xx-light) !important;
    .search-select__indicator-separator {
      background-color: map-get($gray-colors, base) !important;
    }
    .search-select__dropdown-indicator,
    .search-select__indicator {
      color: map-get($gray-colors, dark) !important;
    }
  }
}

.publication-section-style {
  background: map-get($gray-colors, x-light);
  border-radius: 1px;
  border-bottom: 1px solid map-get($blue-colors, base);
}

.publication-section-style-no-border-bottom {
  background: map-get($gray-colors, x-light);
  border-radius: 1px;
}

.publication-section-border-bottom {
  border-bottom: 1px solid map-get($blue-colors, base);
}

.publication-section-danger-text {
  color: map-get($red-colors, x-dark);
}

.table-last-row tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.table thead th {
  vertical-align: top;
  border-top: 1px solid #007e94;
}

.public-section-p-content {
  background-color: #fff;
  min-height: 100px;
  width: 100%;
  padding-top: 20px;
}

.feedback-item {
  margin-bottom: 5px; 
}

.feedback-title {
  font-weight: 700;
  margin-bottom: 1px;
}

.feedback-content {
  display: flex;
  align-items: center;
}

.feedback-heading {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.feedback-description {
  margin-left: 5px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control-text-area-publication {
  display: block;
  border-radius: 8px;
  width: 100%;
  padding: 0.3rem 0.75rem;
  background-color: #fff;
  border: 1px solid map-get($gray-colors, base);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  &:focus-visible {
    border-color: $input-focus-border-color !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 148, 0.25) !important;
  }
}

.form-school-unit-permission {
  max-height: 50vh;
  overflow: auto;
}

.table-container {
  overflow-x: auto;
  max-height: 80vh;
}

.start-idp-logo-preview {
  background-color: map-get($gray-colors, xx-dark);
  height: 36px;
  width: 36px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  padding: 2px;
}

.no-upper {
  text-transform: inherit !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.custom-control {
  z-index: auto;
  left: 0.8rem !important;
}

.custom-radio,
.custom-checkbox {
  display: block;
}

.table-sticky-header thead {
  & th {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
  }
}

.span-text {
  font-weight: 400;
  font-size: 16px !important;
}

#manage-reminders {
  section:last-child {
    border-bottom: none;
  }
}

#manage-reminders {
  @media screen and (max-width: 650px) {
    section {
      h3 {
        font-size: 14px !important;
      }
      small {
        font-size: 10px !important;
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        font-size: 14px !important;
      }
      .span-text {
        font-size: 10px !important;
      }
    }
  }
}
.td-color {
  color: map-get($gray-colors, dark);
  background: white;
}

@media screen and (max-width: 600px) {
  #school-unit-rights {
    float: none !important;
  }
}

.th-border {
  border-right: 1px solid map-get($gray-colors, dark) !important;
  border-bottom: 1px solid map-get($gray-colors, dark) !important;
  vertical-align: middle !important;
}
.th-border-bottom {
  border-bottom: 1px solid map-get($gray-colors, dark) !important;
}
.td-border-bottom {
  border-bottom: 1px solid map-get($gray-colors, dark) !important;
}

.td-border {
  border-right: 1px solid map-get($gray-colors, dark) !important;
  border-bottom: 1px solid map-get($gray-colors, dark) !important;
  // vertical-align: middle !important;
}
.saveBtn {
  border-radius: 15px;
  border: 1px solid white;
  padding: 0.2rem 1.5rem;
  font-size: 14px;
  background: map-get($blue-colors, base) !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}

.saveBtn:hover {
  background-color: map-get($blue-colors, light) !important;
}

@media screen and (max-width: 991px) {
  #mainMenu {
    position: fixed;
    top: 66px;
    width: 280px;
    right: 0;
    background-color: white;
    padding: 0;
    border-bottom-left-radius: 5px;
    box-shadow: $ist-shadow;
    border-bottom: 1px solid map-get($blue-colors, dark);
    border-left: 1px solid map-get($blue-colors, dark);
    ul {
      li {
        border-bottom: 1px solid map-get($gray-colors, dark);
        a {
          padding: 0.5rem 1rem;
          color: map-get($blue-colors, dark) !important;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 12px;
        }
        div {
          padding: 0;
          border: none;
          border-radius: 0;
          a {
            border-top: 1px dashed map-get($gray-colors, dark);
            padding-left: 2rem;
            text-transform: none;
            color: black !important;
            font-weight: 400;
          }
          a.active {
            color: white !important;
          }
          a:hover.active {
            background-color: map-get($gray-colors, base);
            color: map-get($gray-colors, xx-dark) !important;
          }
          a:hover {
            background-color: map-get($gray-colors, base);
          }
        }
      }
      div {
        justify-content: end;
        button {
          margin-right: 1rem;
        }
      }
    }
  }
}
