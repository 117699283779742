$standard-spacing: map-get($spacers, content);
$standard-spacing-lg: map-get($spacers, 4);

#form-view {
  .actions {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;

      // right most group
      &:last-child {
        align-items: flex-end;
      }

      // div wrapping buttons
      & > div {
        margin-top: 15px;
      }

      @media (max-width: map-get($grid-breakpoints, sm)) {
        flex-direction: column;
      }

      @media (min-width: map-get($grid-breakpoints, sm)) {
        & > div:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

#form-viewer-container #form-viewer {
  margin-bottom: $standard-spacing;
}

.icon-button.disabled {
  color: lightgray;
  cursor: not-allowed;
}

.custom-control.form-group {
  margin: 0px 0px 1rem 0px;
}

button.flat.alternative-like {
  cursor: pointer;
  padding: 9px 11px;
  font-weight: bold;
}

.hidden {
  display: none;
}

.flex-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.even-flex {
  display: flex;
  flex: 1;
}

#form-viewer,
#form-creator {
  .option-delete-container {
    margin-left: 20px;
  }

  .form-section {
    background-color: white;
    padding: $standard-spacing $standard-spacing-lg;
    border-radius: $border-radius-lg;
    // box-shadow: $ist-shadow;
    margin-bottom: map-get($spacers, content);
  }

  .form-section.is-editing {
    box-shadow: $ist-shadow-strong;
    .form-component-actions {
      .edit-button {
        visibility: hidden;
      }
    }
  }
  .form-head-section,
  .form-section {
    .form-component-actions {
      visibility: hidden;
    }

    .question-actions {
      margin: 20px 0 20px 0;

      .label-placeholder {
        display: flex;
      }
    }

    .question-actions,
    .form-component-actions {
      .icon-button {
        margin-left: 10px;
      }
      .icon-button.disabled {
        color: lightgray;
        cursor: not-allowed;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
    }
  }
  .form-head-section,
  .form-section:hover {
    .form-component-actions {
      visibility: visible;
    }
  }

  .form-head-section:hover {
    .header .icon-button {
      visibility: visible;
    }
  }
  .form-head-section {
    .header h2,
    .header .icon-button {
      margin: 0;
      color: white;
    }
    .header .icon-button {
      margin-right: $standard-spacing;
      visibility: hidden;
    }
    margin-bottom: $standard-spacing;
    .section-body {
      padding: $standard-spacing-lg;
    }
  }

  .new-component-placeholder {
    border: 4px dashed map-get($gray-colors, base);
    padding: map-get($spacers, content);
    text-align: center;
    button {
      margin: 0px 5px;
    }
    margin-bottom: map-get($spacers, content);
  }

  .form-section.invalid,
  .draftjs-editor.invalid,
  .form-head-section.invalid {
    border: 1px solid red;
  }

  form {
    flex-grow: 2;
  }

  label {
    margin-bottom: 0;
    &.form-input-label {
      color: map-get($blue-colors, base);
      font-size: 12px;
      letter-spacing: 0.5px;
      font-weight: 700;
      margin-bottom: 4px;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }

  .question-container .question-header {
    margin-bottom: 5px;
  }

  .question-container {
    padding: 10px 0;
  }

  .pc-question-enter {
    opacity: 0;
  }

  .pc-question-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      flex: 0;
      transform: translateY(-20px);
    }
    100% {
      height: auto;
      flex: 1;
      opacity: 1;

      transform: translateY(0);
    }
  }

  .question-header {
    color: black;
    .form-group {
      margin-right: 4px;
    }
    .short-name-input {
      label.has-title {
        text-decoration: underline dotted;
      }
    }
    .question-title {
      margin: 0;
    }
  }

  .option-row.thick {
    padding-bottom: 20px;

    label {
      min-width: 30px;
    }
  }

  .option-row {
    display: flex;

    // align-items: center;
    .custom-radio,
    .custom-checkbox {
      display: block;
      margin-right: 10px;
    }
    .custom-radio,
    .custom-checkbox,
    .option-delete-container {
      margin-top: 5px;

      .btn {
        margin-top: -3px;
        margin-left: 5px;
      }

      &.disabled {
        opacity: lightgray;
        cursor: not-allowed;
      }
    }
    .add-option {
      padding: 5px;
      padding-left: 13px;
    }
    input:focus {
      outline: none;
      border-color: map-get($gray-colors, base);
    }
    input {
      border-style: solid;
      border-width: 1px;
    }
    input:not(:focus) {
      border-color: white;
    }
    input.is-invalid {
      border: 1px solid red;
    }
    textarea {
      resize: none;
    }
    textarea:focus {
      outline: none;
      border-color: map-get($gray-colors, base);
    }
    textarea:not(:focus) {
      border-color: white;
    }
    .option-label {
      flex-grow: 1;
    }
  }

  .option-container:not(:last-child) {
    margin-right: 10px;
  }

  .predicates-row {
    display: flex;
  }

  .predicates-box {
    position: relative;
    background: #fbfbfa;
    border-radius: 0.2em;
    border: 1px solid #e5e5e3;
    margin-bottom: 20px;

    .question-container {
      margin: 0px;
    }

    .question-body {
      margin-bottom: 20px;
    }

    textarea:not(:focus) {
      border-color: map-get($gray-colors, base);
    }

    .question-container hr {
      display: block;
    }

    .question-container:nth-last-child(2) {
      margin: 0px;
      margin-bottom: -8px;

      .question-body {
        margin: 0px;
      }

      hr {
        display: none;
      }
    }
  }

  .predicates-box:after {
    content: "";
    position: absolute;
    color: #000;
    top: 0;
    left: 26px;
    width: 14px;
    height: 14px;
    border: 1px solid #d1d1d1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fbfbfa;
    margin-top: -8px;
    border-bottom: 0;
    border-right: 0;
  }
}

.pc-component-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.pc-component-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 200ms ease-out;
}

.pc-component-exit {
  display: none;
}

.pc-component-exit-active {
  display: none;
}
